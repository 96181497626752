import React from "react"
import get from 'lodash/get'
import filter from 'lodash/filter'
import { graphql } from 'gatsby'
import { Layout } from "../components/layout"
import { SEO } from "../components/seo"
import { Schedule } from "../components/ds"
import * as sections from "../components/sections"

const IndexPageTemplate = ({ pageContext, data, location }) => {
  const seoData = get(data, 'sanityPage.seo')
  const contentData = get(data, 'sanityPage.content') || []
  const footerData = get(data, 'sanityFooter')

  const [heroSection] = filter(contentData, { '_type': 'heroSection' })
  const [benefitSection] = filter(contentData, { '_type': 'benefitSection' })
  const [newsletterSection] = filter(contentData, { '_type': 'newsletterSection' })
  const [mentorSection] = filter(contentData, { '_type': 'mentorSection' })
  const [partnerSection] = filter(contentData, { '_type': 'partnerSection' })

  const focusKeyword = get(seoData, 'seoTools.focus_keyword')
  const synonyms = get(seoData, 'seoTools.focus_synonyms') || []

  return (
    <>
      <SEO
        title={get(seoData, 'seoTools.seo_title')}
        description={get(seoData, 'seoTools.meta_description')}
        lang={get(pageContext, 'locale')}
        pathname={get(location, 'pathname')}
        image={get(seoData, 'seoThumbnail.asset.localFile.childImageSharp.resize')}
        keywords={[focusKeyword, ...synonyms]}
      />
      <Layout footer footerContent={footerData} newsletter={false} locale={get(pageContext, 'locale')}>
        <sections.HeroCtaSection content={heroSection} />
        <sections.MentorSectionComplex content={mentorSection} locale={get(pageContext, 'locale')} />
        <sections.PartnerSection content={partnerSection} locale={get(pageContext, 'locale')} />
        <sections.Benefits content={benefitSection} />
        <sections.FinalCta content={newsletterSection} />
        <Schedule locale={get(pageContext, 'locale')} />
        <sections.FinalCta content={newsletterSection} />
      </Layout>
    </>
  )
}

export default IndexPageTemplate

export const query = graphql`
  query MainLandingPage($slug: String, $locale: String) {
    sanityPage(essentials: { slug: { current: { eq: $slug } }, locale: { eq: $locale } } ) {
      seo {
        seoThumbnail {
          asset {
            localFile {
              childImageSharp {
                resize(width: 1200) {
                  src
                  height
                  width
                }
              }
            }
          }
        }
        seoTools {
          focus_keyword
          focus_synonyms
          meta_description
          seo_title
        }
      }
      essentials {
        locale
        slug {
          current
        }
      }
      content {
        ... on SanityHeroSection {
          _key
          _type
          description
          headlinePlainPartOne
          headlinePlainPartTwo
          headlineRollingString
          primaryCtaButtonText
          secondaryCtaButtonText
        }
        ... on SanityBenefitSection {
          _key
          _type
          description
          headline
          subline
          blocks {
            _key
            headline
            description
            icon
          }
        }
        ... on SanityNewsletterSection {
          _key
          _type
          submitButtonText
          postSubmitRedirect
          placeholder
          headline
          description
          PrivacyLinkSlug
          PrivacyInitialLinkText
          PrivacyActualLinkText
        }
        ... on SanityMentorSection {
          _key
          _type
          headline
          _rawDescription
          primaryButtonText
          primaryButtonSlug
          mentors {
            id
            _key
            firstname
            lastname
            socialLinks {
              _key
              profileUrl
              type
            }
            avatar {
              asset {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1024) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
            bio {
              en
              sv
            }
            role {
              en
              sv
            }
          }
        }
        ... on SanityPartnerSection {
          _key
          _type
          headline
          description
          primaryButtonText
          primaryButtonSlug
          partners {
            id
            _key
            name
            website
            socialLinks {
              _key
              profileUrl
              type
            }
            logo {
              asset {
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
    sanityFooter(locale: {eq: $locale}) {
      copyright
      languageSwitcherLabel
      mission
      links {
        _key
        headline
        links {
          _key
          label
          slug
        }
      }
      newsletter {
        headline
        subline
        buttonText
        emailField {
          type
          placeholder
          label
          identifier
          errors {
            _key
            key
            message
          }
        }
        postSubmitRedirectSlug
        toastError {
          headline
          description
        }
      }
      socialLinks {
        _key
        profileUrl
        type
      }
      logo {
        asset {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`
